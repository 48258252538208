@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.uil-spin-css > div {
  position: absolute;
}
.uil-spin-css > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.uil-spin-css > div:nth-of-type(1) > div {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}
.uil-spin-css > div:nth-of-type(1) {
  -ms-transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
  -moz-transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
  -webkit-transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
  -o-transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
  transform: translate(50px, 50px) rotate(45deg) translate(30px, 0);
}
.uil-spin-css > div:nth-of-type(2) > div {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  animation-delay: 0.12s;
}
.uil-spin-css > div:nth-of-type(2) {
  -ms-transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
  -moz-transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
  -webkit-transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
  -o-transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
  transform: translate(50px, 50px) rotate(90deg) translate(30px, 0);
}
.uil-spin-css > div:nth-of-type(3) > div {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.uil-spin-css > div:nth-of-type(3) {
  -ms-transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
  -moz-transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
  -webkit-transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
  -o-transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
  transform: translate(50px, 50px) rotate(135deg) translate(30px, 0);
}
.uil-spin-css > div:nth-of-type(4) > div {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.37s;
  -moz-animation-delay: 0.37s;
  -webkit-animation-delay: 0.37s;
  -o-animation-delay: 0.37s;
  animation-delay: 0.37s;
}
.uil-spin-css > div:nth-of-type(4) {
  -ms-transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
  -moz-transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
  -webkit-transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
  -o-transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
  transform: translate(50px, 50px) rotate(180deg) translate(30px, 0);
}
.uil-spin-css > div:nth-of-type(5) > div {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.uil-spin-css > div:nth-of-type(5) {
  -ms-transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
  -moz-transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
  -webkit-transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
  -o-transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
  transform: translate(50px, 50px) rotate(225deg) translate(30px, 0);
}
.uil-spin-css > div:nth-of-type(6) > div {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.62s;
  -moz-animation-delay: 0.62s;
  -webkit-animation-delay: 0.62s;
  -o-animation-delay: 0.62s;
  animation-delay: 0.62s;
}
.uil-spin-css > div:nth-of-type(6) {
  -ms-transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
  -moz-transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
  -webkit-transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
  -o-transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
  transform: translate(50px, 50px) rotate(270deg) translate(30px, 0);
}
.uil-spin-css > div:nth-of-type(7) > div {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.uil-spin-css > div:nth-of-type(7) {
  -ms-transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
  -moz-transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
  -webkit-transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
  -o-transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
  transform: translate(50px, 50px) rotate(315deg) translate(30px, 0);
}
.uil-spin-css > div:nth-of-type(8) > div {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  animation-delay: 0.87s;
}
.uil-spin-css > div:nth-of-type(8) {
  -ms-transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
  -moz-transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
  -webkit-transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
  -o-transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
  transform: translate(50px, 50px) rotate(360deg) translate(30px, 0);
}